import React, { useState, useCallback } from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import ActivityIntro from '../../components/activity/ActivityIntro';
import ActivityPurpose from '../../components/activity/ActivityPurpose';
import ActivityPlace from '../../components/activity/ActivityPlace';
import LocationDetails from '../../components/activity/LocationDetails';
import ParticipantInformation from '../../components/activity/ParticipantInformation';
import ActivityDetails from '../../components/activity/ActivityDetails';
import EventCost from '../../components/activity/EventCost';
import Summary from '../../components/activity/Summary';
import './ActivityCreation.css';
import Home from "../../pages/ShippiHome/Home"
import { useNavigate } from 'react-router-dom';
import Header from '../../components/activity/header';


function ActivityCreation() {
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({
    description: '',
    purpose: '',
    placeType: '',
    location: '',
    guestDetails: '',
    activityDetails: '',
    costPerPerson: '',
  });

    const navigate = useNavigate();


  const handleValidationChange = useCallback((input, value) => {
    setFormData({ ...formData, [input]: value });
  }, [formData]);

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };


  const handleStart = () => {
    // Show pop-up message here
        console.log('Form Data:', formData);

    alert("Your listing will be live in an hour. We'll review it shortly.");
    navigate('/home');
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return <ActivityIntro onValidationChange={handleValidationChange} values={formData} />;
      case 2:
        return <ActivityPurpose onValidationChange={handleValidationChange} values={formData} />;
      case 3:
        return <ActivityPlace onValidationChange={handleValidationChange} values={formData} />;
      case 4:
        return <LocationDetails onValidationChange={handleValidationChange} values={formData} />;
      case 5:
        return <ParticipantInformation onValidationChange={handleValidationChange} values={formData} />;
      case 6:
        return <ActivityDetails onValidationChange={handleValidationChange} values={formData} />;
      case 7:
        return <EventCost onValidationChange={handleValidationChange} values={formData} />;
      case 8:
        return <Summary values={formData} />;
      default:
        return null;
    }
  };

  return (
    <>
    <Header/>
    <div className='activity'>
      <h2>It's easy to get started on Shippi</h2>
      <div className='form-container'>
        {renderStep()}
        <Stack spacing={2} direction="row" style={{ height: 30 }}>
          {currentStep > 1 && <Button variant="contained" onClick={prevStep}>Back</Button>}
          {currentStep < 8 ? <Button variant="contained" onClick={nextStep}>Next</Button> : <Button variant="contained" onClick={handleStart}>Start</Button>}
        </Stack>
      </div>
    </div>
    </>
  );
}

export default ActivityCreation;
