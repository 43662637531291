import React, { useState } from "react";
import Footer from "../../components/footer/Footer";
import Header from "../../components/Header";
import "./Login.css";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("Email:", email);
    console.log("Password:", password);
  };

  return (
    <div className="App">
      <Header />
      <div className="App-header">
        <div className="content-wrapper">
          <h2 className="heading">Log In</h2>
          <div className="login-box">
            <Box
              component="form"
              className="login-form"
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              <TextField
                label="Email address"
                color="secondary"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Password"
                type="password"
                color="secondary"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                fullWidth
                margin="normal"
              />
              <Link
                to="/home"
                style={{ textDecoration: "none" }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  className="login-button"
                >
                  Log In
                </Button>
              </Link>
            </Box>
            <div className="signup-link">
              <p>
                If you're a new user, please <Link to="/signup">Sign Up</Link>.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Login;
