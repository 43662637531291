import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import ListIcon from '@mui/icons-material/List';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import { useTheme } from '@mui/material/styles';

export default function MediaCard({ post }) {
  const navigate = useNavigate();
  const postData = post ? post : null;

  function onNavigation() {
    navigate('/post');
    console.log("Navigating to /post");
  }

  const truncateText = (text, wordLimit) => {
    const words = text.split(' ');
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(' ') + '...';
    }
    return text;
  };

  console.log("hi post data",postData)

  const truncatedTitle = postData ? truncateText(postData.title, 10) : '';
  const truncatedDescription = postData ? truncateText(postData.content.description, 20) : '';

  return (
    <Card 
      sx={{ 
        maxWidth: { xs: 345, md: 517.5 }, // Increase width by 50% for md and up
        minHeight: 400, 
        display: 'flex', 
        flexDirection: 'column' 
      }}
    >
      {postData && (
        <>
          <div
            style={{
              height: 140,
              background: 'linear-gradient(to right, rgb(236 255 239), rgb(255, 230, 230))',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: '#000',
            }}
          >
            <Typography variant="h5" style={{ fontSize: '24px', fontWeight: "bold" }}>
              {postData.category}
            </Typography>
          </div>
          <CardContent style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <Typography gutterBottom variant="h5" component="div" style={{ fontSize: '20px' }}>
              {truncatedTitle}
            </Typography>
            <Typography variant="body2" color="text.secondary" style={{ marginBottom: 10 }}>
              {truncatedDescription}
            </Typography>
            <div>
              <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', fontWeight: "bold" }}>
                <ListIcon sx={{ color: '#ff6d75', fontSize: 20, marginRight: '8px' }} /> {postData.category}
              </Typography>
              <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', fontWeight: "bold" }}>
                <WorkOutlineIcon sx={{ color: '#ff6d75', fontSize: 20, marginRight: '8px' }} /> {postData.companies.join(', ')}
              </Typography>
            </div>
          </CardContent>
        </>
      )}
      <CardActions style={{ justifyContent: 'center' }}>
        <div style={{ textAlign: 'center', color: '#000000' }}>Learn more</div>
      </CardActions>
    </Card>
  );
}
