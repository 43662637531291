import React from 'react';
import './Summary.css';

const Summary = ({ values }) => {
  return (
    <div className="summary">
      <h3 className="summary-title">Summary</h3>
      <div className="summary-details">
        <p><strong>Description:</strong> {values.description}</p>
        <p><strong>Purpose:</strong> {values.purpose}</p>
        <p><strong>Place Type:</strong> {values.placeType}</p>
        <p><strong>Location:</strong> {values.location}</p>
        <p><strong>Guest Details:</strong> {values.guestDetails}</p>
        <p><strong>Activity Details:</strong> {values.activityDetails}</p>
        <p><strong>Cost per Person:</strong> ${values.costPerPerson}</p>
      </div>
    </div>
  );
};

export default Summary;
