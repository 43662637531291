import Header from"../../components/Header"
import Footer from "../../components/footer/Footer";
function Contact() {
  return (
    <div> 
      <Header/>
    <div style={{ padding: "20px", fontFamily: "Arial, sans-serif", color: "#333" }}>
      <h1 style={{ color: "#ff6d75" }}>Contact Us</h1>
      <p>If you have any questions or need further information, please feel free to contact us:</p>
      <ul>
        <li>Email: <a href="mailto:support@shippi.com" style={{ color: "#ff6d75" }}>shippilife@gmail.com</a></li>
        <li>Phone: <a href="tel:+1234567890" style={{ color: "#ff6d75" }}>(123) 456-7890</a></li>
      </ul>
      <p>We look forward to hearing from you and assisting with your shipping needs!</p>
    </div>
    <Footer/>
    </div>
  );
}

export default Contact;
