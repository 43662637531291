import React, { useState } from 'react';
import './LocationDetails.css';

const LocationDetails = ({ onValidationChange, values }) => {
  const [location, setLocation] = useState(values.location || '');
  const [isValid, setIsValid] = useState(true); // Initial validation state

  const handleChange = (e) => {
    const value = e.target.value;
    setLocation(value);
    const isValidInput = value.trim().length > 0; // Validate input as non-empty
    setIsValid(isValidInput);
    onValidationChange('location', value); // Notify parent component of the value change
  };

  return (
    <div className="location-details">
      <h3>Where's the place located?</h3>
      <form>
        <div className="form-group">
          <label htmlFor="location">Location</label>
          <input
            type="text"
            id="location"
            name="location"
            value={location}
            onChange={handleChange}
            placeholder="Enter location"
            required
          />
          {!isValid && <p className="error-message">Please enter a location</p>}
        </div>
      </form>
    </div>
  );
};

export default LocationDetails
