import Header from "../../components/Header";
import Footer from "../../components/footer/Footer";

function AboutUs() {
  return (
    <div>
      <Header />
      <div style={{ padding: "20px", fontFamily: "Arial, sans-serif", color: "#333" }}>
        <h1 style={{ color: "#ff6d75" }}>About Us</h1>
        
        <p>Welcome to Shippi, where we believe in the power of connections to transform lives. Our platform is more than just a networking tool; it's a catalyst for personal and professional growth, providing tailored experiences and resources to help individuals reach their full potential. We are dedicated to creating an environment where users can explore new opportunities, develop their skills, and build lasting relationships.</p>
        
        <p>At Shippi, we understand the challenges individuals face in today's fast-paced world. Despite the abundance of networking platforms, finding meaningful connections and opportunities for growth can be daunting. That's why we've created a platform that goes beyond traditional networking to offer personalized solutions for every user. We aim to bridge the gap between digital networking and real-world experiences, fostering a community where everyone can thrive.</p>
        
        <p>Our mission is simple: to empower individuals to connect, learn, and grow in ways that are meaningful and impactful. Whether you're looking to expand your professional network, enhance your cognitive skills, or embark on new adventures, Shippi is here to support you every step of the way. Our comprehensive approach ensures that users can benefit from a wide range of features and resources tailored to their specific needs and goals.</p>

        <h2 style={{ color: "#ff6d75" }}>Our Vision</h2>
        <p>Our vision is to create a world where everyone has the opportunity to reach their full potential through meaningful connections and continuous learning. We believe that by fostering a supportive and dynamic community, we can help individuals achieve their personal and professional goals, explore new horizons, and make a positive impact in their lives and the lives of others.</p>

        <h2 style={{ color: "#ff6d75" }}>Key Features</h2>
        <p>Shippi offers a range of features designed to enhance your networking and personal development experience:</p>
        <ul>
          <li>Personalized Listings: Host and join activities tailored to your interests and preferences. Whether it's a professional seminar, a social dinner, or an adventurous trip, you'll find opportunities that match your passions.</li>
          <li>Brain Development Hub: Access a curated collection of resources and challenges designed to stimulate cognitive skills and promote problem-solving abilities. Our content is tailored to various fields and interests, ensuring that you can continuously grow and develop.</li>
          <li>Interactive User Profiles: Showcase your interests, skills, and experiences to connect with like-minded individuals. Our profiles are designed to highlight your unique strengths and achievements, making it easier to find and connect with people who share your goals.</li>
          <li>Seamless Communication: Stay connected with built-in messaging and notification systems. Our platform ensures that you can easily coordinate with others, stay informed about upcoming events, and maintain meaningful interactions.</li>
          <li>Professional Development Opportunities: Participate in workshops, seminars, and mentorship programs to enhance your skills and knowledge. We provide access to a variety of learning experiences that can help you advance in your career and achieve your professional aspirations.</li>
          <li>Community Engagement: Engage with a vibrant community of individuals who share your passions and aspirations. Our community is a supportive space where you can share ideas, seek advice, and collaborate on projects.</li>
        </ul>

        <h2 style={{ color: "#ff6d75" }}>Why Choose Shippi?</h2>
        <p>Shippi stands out from other networking platforms due to its holistic approach to personal and professional development. Here's why you should choose Shippi:</p>
        <ul>
          <li><strong>Personalized Experience:</strong> Our platform is designed to cater to your unique needs and interests. We offer tailored recommendations and opportunities that align with your goals.</li>
          <li><strong>Comprehensive Resources:</strong> From cognitive skill challenges to professional workshops, Shippi provides a wide range of resources to support your growth and development.</li>
          <li><strong>Inclusive Community:</strong> Shippi fosters an inclusive environment where everyone is welcome. We believe in the power of diversity and strive to create a space where individuals from all backgrounds can thrive.</li>
          <li><strong>Real-World Impact:</strong> We bridge the gap between digital networking and real-world experiences, offering opportunities that have a tangible impact on your life and career.</li>
          <li><strong>Continuous Learning:</strong> Shippi is committed to continuous improvement and lifelong learning. Our platform evolves with your needs, ensuring that you always have access to the latest tools and resources.</li>
        </ul>

        <h2 style={{ color: "#ff6d75" }}>Our Community</h2>
        <p>The Shippi community is at the heart of everything we do. Our members are passionate, driven individuals who are dedicated to personal and professional growth. By joining Shippi, you become part of a network of like-minded individuals who support and inspire each other. Here are some ways you can engage with the community:</p>
        <ul>
          <li><strong>Networking Events:</strong> Attend events and meetups to connect with other members, share ideas, and build meaningful relationships.</li>
          <li><strong>Online Forums:</strong> Participate in discussions and share your experiences in our online forums. This is a great way to seek advice, offer support, and stay connected with the community.</li>
          <li><strong>Collaborative Projects:</strong> Collaborate with other members on projects and initiatives that align with your interests. Whether it's a community service project or a professional collaboration, you'll find opportunities to work together and make a difference.</li>
          <li><strong>Mentorship Programs:</strong> Take advantage of our mentorship programs to receive guidance and support from experienced professionals. Mentorship is a key component of growth, and our programs are designed to help you navigate your career path effectively.</li>
        </ul>

        <h2 style={{ color: "#ff6d75" }}>Our Commitment to Privacy</h2>
        <p>At Shippi, we take your privacy seriously. We are committed to protecting your personal information and ensuring that your data is handled with the utmost care. Our privacy policy outlines how we collect, use, and protect your information. Here are some key points:</p>
        <ul>
          <li>We only collect information that is necessary for providing our services and enhancing your experience on the platform.</li>
          <li>Your data is stored securely, and we use advanced security measures to prevent unauthorized access.</li>
          <li>We do not share your personal information with third parties without your consent, except as required by law.</li>
          <li>You have control over your data, including the ability to update, delete, or request a copy of your information at any time.</li>
        </ul>
        <p>If you have any questions or concerns about our privacy practices, please contact us at <a href="mailto:privacy@shippi.com" style={{ color: "#ff6d75" }}>privacy@shippi.com</a>.</p>

        <h2 style={{ color: "#ff6d75" }}>Join Us</h2>
        <p>At Shippi, we're always looking for passionate individuals to join our team. If you're interested in helping us achieve our mission and making a positive impact, we'd love to hear from you. Check out our career opportunities on our website and see how you can be a part of the Shippi community.</p>
        
        <h2 style={{ color: "#ff6d75" }}>Contact Us</h2>
        <p>For any inquiries, feedback, or partnership opportunities, please don't hesitate to reach out to us at <a href="mailto:shippilife@gmail.com" style={{ color: "#ff6d75" }}>shippilife@gmail.com</a>. We'd love to hear from you and explore how we can work together to create a better future for all.</p>
        
        <h2 style={{ color: "#ff6d75" }}>Testimonials</h2>
        <p>Don't just take our word for it. Here's what some of our users have to say about their experience with Shippi:</p>
        <ul>
          <li>"Shippi has transformed the way I connect with others. The personalized listings and brain development resources have helped me grow both personally and professionally." - Jane D.</li>
          <li>"The community on Shippi is incredibly supportive and inspiring. I've made so many meaningful connections and have learned so much from the resources available on the platform." - John S.</li>
          <li>"Shippi's approach to networking is truly unique. The focus on cognitive skill enhancement and real-world experiences sets it apart from other platforms." - Emily R.</li>
        </ul>
        <p>We are proud of the positive impact Shippi has had on our users' lives and are committed to continuing to provide valuable resources and opportunities for growth.</p>

        <h2 style={{ color: "#ff6d75" }}>Future Plans</h2>
        <p>At Shippi, we are constantly looking for ways to improve and expand our platform. Our future plans include:</p>
        <ul>
          <li><strong>Expanding Our Reach:</strong> We aim to reach more individuals globally, providing access to our platform and resources to a wider audience.</li>
          <li><strong>Enhanced Features:</strong> We are working on new features and tools to further enhance the user experience, including advanced networking algorithms and AI-driven recommendations.</li>
          <li><strong>Partnerships:</strong> We plan to establish partnerships with organizations and institutions to offer exclusive opportunities and resources to our users.</li>
          <li><strong>Community Initiatives:</strong> We are committed to giving back to the community through various initiatives and programs that promote personal and professional development.</li>
        </ul>
        <p>We are excited about the future of Shippi and are dedicated to continuing our mission of empowering individuals to connect, learn, and grow.</p>

      </div>
      <Footer />
    </div>
  );
}

export default AboutUs;
