import React, { useState } from 'react';
import { TextField, Button, Container, Grid } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ListingT1 from '../../components/cards/ListingT1'; 
import './Home.css'; 
import Search from '../../components/search/search';
import ScrollableChips from '../../components/scrollableChips/scrollableChips';
import Header from '../../components/activity/header';


const Home = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [appliedFilters, setAppliedFilters] = useState([]); 
  const [listings, setListings] = useState([
    // Dummy data for initial listings based on the model
    {
      activityId: 'activity123',
      type: 'Dinner',
      title: 'Hosting Dinner, Baking cake',
      date: '2024-06-10',
      time: '18:00',
      location: '145 k-Block, Kormangalam, Bengaluru',
      additionalDetails: 'Please bring your empty stomach',
      hostId: 'RJbhusan',
      invitedUsers: ['Mehul', 'Ankush'],
      costDetails: {
        totalCost: 3000,
        items: [
          { itemName: 'Food', itemCost: 50 },
          { itemName: 'Drinks', itemCost: 50 },
        ],
      },
      status: 'Pending',
      images: ['https://picsum.photos/seed/picsum/450/300'],
    },
    {
      activityId: 'activity456',
      type: 'Outdoor',
      title: 'Mountain Hiking Adventure',
      date: '2024-07-10',
      time: '09:00',
      location: 'Rocky Mountains',
      additionalDetails: 'Bring water and snacks.',
      hostId: 'user456',
      invitedUsers: ['user123', 'user789'],
      costDetails: {
        totalCost: 200,
        items: [
          { itemName: 'Guide', itemCost: 100 },
          { itemName: 'Equipment', itemCost: 100 },
        ],
      },
      status: 'Approved',
      images: ['https://picsum.photos/seed/picsum/450/300'],
    },
    // Add more dummy data for listings
    {
      activityId: 'activity789',
      type: 'Cooking',
      title: 'Italian Cooking Class',
      date: '2024-08-15',
      time: '14:00',
      location: 'Culinary School',
      additionalDetails: 'Learn to make authentic Italian dishes.',
      hostId: 'user789',
      invitedUsers: ['user123', 'user456'],
      costDetails: {
        totalCost: 50,
        items: [
          { itemName: 'Ingredients', itemCost: 20 },
          { itemName: 'Instructor', itemCost: 30 },
        ],
      },
      status: 'Pending',
      images: ['https://picsum.photos/seed/picsum/450/300'],
    },
    // Add more dummy data for listings...
  ]);


  const chipData = ['Dinner', 'Outdoor', 'Cooking', 'Hiking', 'Adventure', 'Indoors', 'Outdoors'];


  const handleSearch = (query) => {
    console.log('Search Query:', searchQuery);
    setSearchQuery(query);

  };

  const handleChipClick = (chip) => {
    // Check if the chip is already applied
    if (appliedFilters.includes(chip)) {
      // Remove the chip from applied filters
      setAppliedFilters(appliedFilters.filter((filter) => filter !== chip));
    } else {
      // Add the chip to applied filters
      setAppliedFilters([...appliedFilters, chip]);
    }
  };
  const filteredListings = listings.filter((listing) => {
    // Check if any of the applied filters match the listing's type
    const filterMatch =
      appliedFilters.length === 0 || appliedFilters.includes(listing.type);
    // Check if the search query matches the listing's title
    const searchMatch =
      searchQuery.trim() === '' ||
      listing.title.toLowerCase().includes(searchQuery.toLowerCase());
    return filterMatch && searchMatch;
  });

  return (
   <>
     <Header/>
    <Container className="home-container">
    <Search searchQuery={searchQuery} setSearchQuery={setSearchQuery} handleSearch={handleSearch} />
    <ScrollableChips data={chipData} onChipClick={handleChipClick} />
    <Grid container spacing={3} className="listings-container">
      {filteredListings.map((listing) => (
        <Grid item xs={12} sm={6} md={4} key={listing.activityId}>
          <div className="listing-card">
            <ListingT1 listing={listing} />
          </div>
        </Grid>
      ))}
    </Grid>
  </Container>
   </>
  );
};

export default Home;
 