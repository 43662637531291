import React, { useState } from "react";
import Footer from "../../components/footer/Footer";
import Header from "../../components/Header";
import "./preferences.css";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";

const preferencesList = [
  "Traveling",
  "Meetup",
  "Mentoring",
  "Dinners",
  "Sports",
  "Reading",
  "Gaming",
  "Music",
  "Cooking",
  "Photography",
  "Hiking",
  "Painting",
  "Yoga",
  "Coding",
  "Writing",
  "Singing",
];

function Preferences() {
  const [selectedPreferences, setSelectedPreferences] = useState([]);
  const [open, setOpen] = useState(false); // For controlling the dialog visibility
  const [loading, setLoading] = useState(false); // For controlling the loading state

  const handlePreferenceClick = (preference) => {
    setSelectedPreferences((prevPreferences) =>
      prevPreferences.includes(preference)
        ? prevPreferences.filter((p) => p !== preference)
        : [...prevPreferences, preference]
    );
  };

  const handleSubmit = () => {
    setLoading(true); // Start loading

    // Simulate an API call or any asynchronous task
    setTimeout(() => {
      setOpen(true); // Open the dialog
      setLoading(false); // Stop loading
    }, 1000); // Simulating 3 seconds of loading time
  };

  const handleClose = () => {
    setOpen(false); // Close the dialog
  };

  return (
    <div className="App">
      <Header />
      <header className="App-header">
        <div className="content-wrapper">
          <h2 className="heading">Select Your Preferences</h2>
          <div className="preferences-box">
            {preferencesList.map((preference) => (
              <div
                key={preference}
                className={`preference-item ${
                  selectedPreferences.includes(preference) ? "selected" : ""
                }`}
                onClick={() => handlePreferenceClick(preference)}
              >
                {preference}
              </div>
            ))}
          </div>
          <Button
            type="button"
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </div>
      </header>
      <Footer />

      {/* Dialog for popup */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{"Account Under Review"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Your account is under review. You'll receive an email if your account gets verified.
          </DialogContentText>
          {loading && <CircularProgress />} {/* Loader */}
        </DialogContent>
        <DialogActions>
        <Link to="/home" >
          <Button onClick={handleClose} color="primary" disabled={loading}>
            OK
          </Button>
          </Link>
        </DialogActions>
      </Dialog>

      
    </div>
  );
}

export default Preferences;
