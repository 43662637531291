import logo from "./logo-shippi.png";
import "./App.css";
import Card from '../src/components/Card'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import StaticHome from "./pages/static/Home"; 
import About from "./pages/static/About";
import BlogList from "./pages/Blog/BlogList";
import BlogPost from "./pages/Blog/BlogPost";
import PrivacyPolicy from "./pages/static/PrivacyPolicy";
import AboutUs from "./pages/static/AboutUs";
import Contact from "./pages/static/Contact";
import BlogPostGeneral from "./pages/Blog/BlogPostGeneral";
import Login from "./pages/LogIn/LogIn";
import SignUp from "./pages/LogIn/SignUp";
import Preferences from "./pages/LogIn/preferences";
import Home from "./pages/ShippiHome/Home";
import ActivityCreation from "./pages/Activity/ActivityCreation";
import ExpDetails from "./pages/ExpDetail/ExpDetails";
import BookingExp from "./pages/Booking/BookingExp";
 
function App() {
  return (
    <>  
    <BrowserRouter>
     <Routes>
      <Route path="/" element={<StaticHome/>}/>
      <Route path="/login" element={<Login/>}/>
      <Route path="/start-activity" element={<ActivityCreation/>}/>
      <Route path="/experience/:id" element={<ExpDetails/>}/>
      <Route path="/booking/:activityId" element={<BookingExp/>}/>
      <Route path="/signup" element={<SignUp/>}/>
      <Route path="/home" element={<Home/>}/>
      <Route path="/preferences" element={<Preferences/>}/>
      <Route path="/blog" element={<BlogList/>}/>
      <Route path="/post/:id/:title" element={<BlogPost />} />
      <Route path="/about" element={<About/>}/>
      <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
      <Route path="/about-us" element={<AboutUs/>}/>
      <Route path="/contact" element={<Contact/>}/>
      <Route path="/blog/post/:id/:title" element={<BlogPostGeneral/>}/>
    </Routes>
     </BrowserRouter>
    </>
  );
}

export default App;
