// ScrollableChips.js
import React from 'react';
import { Chip, Paper } from '@mui/material';
import './ScrollableChips.css'; // Import CSS for styling

const ScrollableChips = ({ data, onChipClick }) => {
  const handleChipClick = (chip) => {
    if (onChipClick) {
      onChipClick(chip);
    }
  };

  return (
    <div className="scrollable-chips-container">
      <Paper elevation={3} className="scrollable-chips-paper">
        {data.map((item, index) => (
          <Chip
            key={index}
            label={item}
            className="chip"
            onClick={() => handleChipClick(item)} // Add onClick handler to each chip
            clickable
          />
        ))}
      </Paper>
    </div>
  );
};

export default ScrollableChips;
