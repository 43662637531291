import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import { Card, CardContent, Typography, CardMedia, CardActions, Button } from '@mui/material';
import './ListingT1.css'; // Assuming you'll add some styles here

const ListingT1 = ({ listing }) => {
  return (
    <Card className="listing-card">
      <CardMedia
        component="img"
        height="140"
        image={listing.images[0]} // Using the first image URL from the listing data
        alt="Listing Image"
      />
      <CardContent>
        <Typography variant="h5" component="div">
          {listing.title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {listing.type} • {listing.date} • {listing.time} • {listing.location}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Additional Detail: {listing.additionalDetails}
        </Typography>
        <Typography variant="body1" component="div">
        ₹{listing.costDetails.totalCost} total
        </Typography>
       </CardContent>
      <CardActions>
        {/* Use Link to navigate to the experience details page */}
        <Link to={`/experience/${listing.activityId}`} style={{ textDecoration: 'none' }}>
          <button className="btn btn-outline btn-accent">Book</button>
        </Link>
      </CardActions>
    </Card>
  );
};

export default ListingT1;
