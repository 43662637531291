import React, { useState } from 'react';

const EventCost = ({ onValidationChange, values }) => {
  const [costPerPerson, setCostPerPerson] = useState(values.costPerPerson || '');
  const [isValid, setIsValid] = useState(true); // Initial validation state

  const handleChange = (e) => {
    const value = e.target.value;
    setCostPerPerson(value);
    const isValidInput = /^\d*\.?\d*$/.test(value); // Validate input as numeric
    setIsValid(isValidInput);
    onValidationChange('costPerPerson', value); // Notify parent component of the value change
  };

  return (
    <div className="event-cost">
      <h3>Event cost, which will be charged per person</h3>
      <form>
        <div className="form-group">
          <label htmlFor="costPerPerson">Cost per Person</label>
          <input
            type="text"
            id="costPerPerson"
            name="costPerPerson"
            value={costPerPerson}
            onChange={handleChange}
            required
          />
          {!isValid && <p className="error-message">Please enter a valid cost</p>}
        </div>
      </form>
    </div>
  );
};

export default EventCost;
