import React from 'react';
import { useParams } from 'react-router-dom';
import { Container, Typography, Divider, Grid, Button, Chip } from '@mui/material';
import { Rating } from '@mui/material';
import './ExpDetails.css'; // Import the corresponding CSS file
import Header from '../../components/activity/header';
 
import { Link } from 'react-router-dom'; 
const ExpDetails = () => {
  // Retrieve the activityId from the URL
  const { id } = useParams();
 
  // Dummy data for activities
  const activities = [
    {
      activityId: 'activity123',
      title: 'Hosting Dinner, Baking cake',
      type: 'Dinner',
      date: '2024-06-08',
      time: '18:00',
      location: '145 k-Block, Kormangalam, Bengaluru',
      additionalDetails: 'Please bring your empty stomach',
      hostId: 'RJbhusan',
      invitedUsers: ['Mehul', 'Ankush'],
      status: 'Verified',
      costDetails: {
        totalCost: 3000,
        items: [
          { itemName: 'Food', itemCost: 1500 },
          { itemName: 'Drinks', itemCost: 1500 }
        ]
      },
      description: 'Join us for an evening of networking, delightful cuisine, and homemade cake!',
      learnings: 'Elevate your professional connections and broaden your network while enjoying a memorable dining experience.',
      inclusions: ['Food', 'Drinks'],
      host: {
        name: 'Rajat bhusan',
        description: 'Serial entrepreneur with a passion for networking and community building.',
        feedbacks: [
          { userId: 'Divya', rating: 4, comment: 'Great host!' },
          { userId: 'Rahul', rating: 5, comment: 'Excellent event!' }
        ]
      }
    },
    {
      activityId: "activity456",
      type: "Cooking",
      title: "Italian Cooking Class",
      date: "2024-08-15",
      time: "14:00",
      location: "Culinary School",
      additionalDetails: "Learn to make authentic Italian dishes.",
      hostId: "user789",
      invitedUsers: ["user123", "user456"],
      status: "Pending",
      costDetails: {
        totalCost: 50,
        items: [
          { itemName: "Ingredients", itemCost: 20 },
          { itemName: "Instructor", itemCost: 30 }
        ]
      },
      description: "Join us for a hands-on cooking class where you'll learn to prepare delicious Italian dishes.",
      learnings: "Master the art of Italian cooking and impress your friends and family with your culinary skills.",
      inclusions: ["Ingredients", "Instruction"],
      host: {
        name: "Maria Rossi",
        description: "Experienced chef with a passion for teaching and sharing her love for Italian cuisine.",
        feedbacks: [
          { userId: "user3", rating: 5, comment: "Fantastic experience! Learned a lot." },
          { userId: "user4", rating: 4, comment: "Great instructor, delicious food." }
        ]
      },
      images: ["https://source.unsplash.com/featured/?cooking"]
    },
    {
      activityId: "activity101",
      type: "Art",
      title: "Oil Painting Workshop",
      date: "2024-09-20",
      time: "15:30",
      location: "Art Studio",
      additionalDetails: "No prior experience required.",
      hostId: "user101",
      invitedUsers: ["user456", "user789"],
      status: "Approved",
      costDetails: {
        totalCost: 80,
        items: [
          { itemName: "Art Supplies", itemCost: 50 },
          { itemName: "Instructor", itemCost: 30 }
        ]
      },
      description: "Unlock your creativity with this hands-on oil painting workshop.",
      learnings: "Discover techniques to create stunning oil paintings and express your artistic vision.",
      inclusions: ["Art Supplies", "Instruction"],
      host: {
        name: "Emily Johnson",
        description: "Professional artist passionate about sharing her expertise and inspiring others through art.",
        feedbacks: [
          { userId: "user5", rating: 5, comment: "Amazing workshop! Learned a lot and had fun." },
          { userId: "user6", rating: 4, comment: "Great instructor, beautiful location." }
        ]
      },
      images: ["https://source.unsplash.com/featured/?painting"]
    }
  ];

  // Find the activity with the matching activityId
  const activity = activities.find((item) => item.activityId === id);

  if (!activity) {
    return <div>No activity found</div>; // Handle case where activity is not found
  }

 

  return (
    <>
    <Header/>
    <Container className="exp-details-container">
      {/* Scrollable images */}
      <div className="scrollable-images">
        {/* Add images here */}
      </div>
      
      <Typography variant="h2" className="title">
        {activity.title}
      </Typography>
      <div className="location-host">
        <Typography variant="subtitle1">{activity.location}</Typography>
        <Typography variant="subtitle1">Host: {activity.hostId}</Typography>
      </div>
      
      {/* Price and Attend button */}
      <div className="price-attend">
        <Typography variant="subtitle1">Total Cost: ${activity.costDetails.totalCost}</Typography>
        <Link to={`/booking/${activity.activityId}`}>
        <Button  variant="contained" color="primary">Attend</Button>
        </Link>
      </div>
      
      <Divider className="divider" />
      
      {/* Details grid */}
      <Grid container spacing={2} className="details-grid">
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle1">Date: {activity.date}</Typography>
          <Typography variant="subtitle1">Time: {activity.time}</Typography>
          <Typography variant="subtitle1">Description: {activity.description}</Typography>
          <Typography variant="subtitle1">Learnings: {activity.learnings}</Typography>
          <Typography variant="subtitle1">Inclusions:</Typography>
          {activity.inclusions.map((item, index) => (
            <Chip key={index} label={item} variant="outlined" />
          ))}
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle1">Host: {activity.host.name}</Typography>
          <Typography variant="subtitle1">Description: {activity.host.description}</Typography>
          <Typography variant="subtitle1">Feedbacks:</Typography>
          {activity.host.feedbacks.map((feedback, index) => (
            <div key={index}>
              <Typography variant="subtitle2">User: {feedback.userId}</Typography>
              <Rating name="read-only" value={feedback.rating} readOnly />
              <Typography variant="subtitle2">Comment: {feedback.comment}</Typography>
            </div>
          ))}
        </Grid>
      </Grid>
    </Container>
    </>
    
  );
};

export default ExpDetails;