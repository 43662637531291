import React, { useState } from 'react';
import './ActivityDetails.css';

const ActivityDetails = ({ onValidationChange, values }) => {
  const [activityDetails, setActivityDetails] = useState(values.activityDetails || '');

  const handleActivityDetailsChange = (e) => {
    const newActivityDetails = e.target.value;
    setActivityDetails(newActivityDetails);
    onValidationChange('activityDetails', newActivityDetails); // Notify parent component of the value change
  };

  return (
    <div className="activity-details">
      <h3>Tell more about the activity or experience, what will be the learnings at the end of the experience?</h3>
      <form>
        <div className="form-group">
          <label htmlFor="activityDetails">Activity Details</label>
          <textarea
            id="activityDetails"
            name="activityDetails"
            value={activityDetails}
            onChange={handleActivityDetailsChange}
            placeholder="Enter activity details..."
            required
          />
        </div>
      </form>
    </div>
  );
};

export default ActivityDetails;
