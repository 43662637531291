import React, { useState } from 'react';
import './ParticipantInformation.css';

const ParticipantInformation = ({ onValidationChange, values }) => {
  const [guestDetails, setGuestDetails] = useState(values.guestDetails || '');
  const [isValid, setIsValid] = useState(true); // Initial validation state

  const handleChange = (e) => {
    const value = e.target.value;
    setGuestDetails(value);
    const isValidInput = value.trim().length > 0; // Validate input as non-empty
    setIsValid(isValidInput);
    onValidationChange('guestDetails', value); // Notify parent component of the value change
  };

  return (
    <div className="participant-information">
      <h3>Share basics about people, guest name, and about food</h3>
      <form>
        <div className="form-group">
          <label htmlFor="guestDetails">Guest Details</label>
          <textarea
            id="guestDetails"
            name="guestDetails"
            value={guestDetails}
            onChange={handleChange}
            placeholder="Enter guest details..."
            required
          />
          {!isValid && <p className="error-message">Please enter guest details</p>}
        </div>
      </form>
    </div>
  );
};

export default ParticipantInformation;
