import React, { useState } from 'react';
import './ActivityPlace.css';

const ActivityPlace = ({ onValidationChange, values }) => {
  const [placeType, setPlaceType] = useState(values.placeType || '');

  const handlePlaceTypeChange = (newPlaceType) => {
    setPlaceType(newPlaceType);
    onValidationChange('placeType', newPlaceType); // Notify parent component of the value change
  };

  return (
    <div className="activity-place">
      <h3>Where will the activity take place?</h3>
      <div className="place-options">
        <div
          className={`place-option ${placeType === 'home' ? 'selected' : ''}`}
          onClick={() => handlePlaceTypeChange('home')}
        >
          Home
        </div>
        <div
          className={`place-option ${placeType === 'outdoor' ? 'selected' : ''}`}
          onClick={() => handlePlaceTypeChange('outdoor')}
        >
          Outdoor
        </div>
        <div
          className={`place-option ${placeType === 'club' ? 'selected' : ''}`}
          onClick={() => handlePlaceTypeChange('club')}
        >
          Club
        </div>
      </div>
    </div>
  );
};

export default ActivityPlace;
