import Header from "../../components/Header";
import Footer from "../../components/footer/Footer";
import { Avatar, Typography, Grid, Card, CardContent } from '@mui/material';
 
function PrivacyPolicy() {
  return (
    <div>
      <Header />
      <div style={{ padding: "20px", fontFamily: "Arial, sans-serif", color: "#333" }}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12}>
            <Typography variant="h4" style={{ color: "#ff6d75" }}>Privacy Policy</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">Your privacy is important to us. This privacy statement provides information about the personal information that Shippi collects and how we use it.</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">Shippi may collect and use the following types of personal information:</Typography>
            <ul>
              <li>Information about your use of this website, including but not limited to, IP address, browser type, device information, and operating system.</li>
              <li>Information you provide when registering with the website, including your name, email address, date of birth, gender, and other demographic information.</li>
              <li>Information about transactions carried out over this website, such as purchase history, payment details, and billing information.</li>
              <li>Information you provide for subscribing to the website services, including preferences, interests, and communication preferences.</li>
              <li>Any other information that you voluntarily send to Shippi, such as feedback, comments, or inquiries.</li>
            </ul>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">Shippi will take reasonable technical and organizational precautions to prevent the loss, misuse, or alteration of your personal information. We utilize encryption, access controls, and other security measures to protect your data.</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">We may use your personal information to:</Typography>
            <ul>
              <li>Provide and personalize our services, including user account management, content delivery, and customer support.</li>
              <li>Process transactions and payments, including order fulfillment, billing, and invoicing.</li>
              <li>Communicate with you about your account, updates, promotions, and other relevant information.</li>
              <li>Analyze user behavior and preferences to improve our services, enhance user experience, and develop new features.</li>
              <li>Comply with legal obligations, resolve disputes, and enforce our policies.</li>
            </ul>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">We may share your personal information with trusted third parties, including service providers, payment processors, marketing partners, and legal authorities, as necessary to fulfill our contractual obligations, comply with legal requirements, or protect our rights.</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">Shippi respects your privacy rights and provides you with the following options:</Typography>
            <ul>
              <li>Access and update your personal information through your user account settings.</li>
              <li>Request deletion of your personal information, subject to applicable legal requirements.</li>
              <li>Opt-out of marketing communications by following the unsubscribe instructions included in each email.</li>
              <li>Exercise your data protection rights under applicable privacy laws, such as the right to access, rectification, erasure, and restriction of processing.</li>
            </ul>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">We may update this policy from time to time by posting a new version on our website. You should check this page occasionally to ensure you are familiar with any changes. Your continued use of our services after any modifications indicates your acceptance of the updated policy.</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">If you have any questions about this privacy policy or Shippi's treatment of your personal information, please contact us at <a href="mailto:shippilife@gmail.com<" style={{ color: "#ff6d75" }}>shippilife@gmail.com</a>.</Typography>
          </Grid>
        </Grid>
      </div>
      <Footer />
    </div>
  );
}

export default PrivacyPolicy;
