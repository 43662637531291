import React, { useState, useCallback } from "react";
import Header from "./header.js"
import "./ActivityIntro.css";

function ActivityIntro({ introValid, onValidationChange }) {
  const [introText, setIntroText] = useState("");
  const [validation, setValidation] = useState(false);

  const handleChange = useCallback(
    (event) => {
      setIntroText(event.target.value);
      const isValid = event.target.value.length >= 10;
      setValidation(isValid);
      onValidationChange("description", introText);
    },
    [onValidationChange]
  );

  return (
    <>
     <div className="intro-container">
     
      <h3>Describe the event and the experience you'll offer to your guests</h3>
      <label htmlFor="description" className="intro-label">
        Event Description
      </label>

      <textarea
        value={introText}
        onChange={handleChange}
        className={`intro-textarea ${validation ? "valid" : "invalid"}`}
      />
    </div>
    </>
    
  );
}

export default ActivityIntro;
