import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import './Booking.css'; // Import the corresponding CSS file
import { Link } from 'react-router-dom'; 
import CircularProgress from '@mui/material/CircularProgress'; // Import CircularProgress from Material-UI
import Header from '../../components/activity/header';

function BookingExp() {
  const { activityId } = useParams(); // Accessing activityId from URL

  // State to manage user input and loading state
  const [hostSuggestions, setHostSuggestions] = useState('');
  const [confirmInfo, setConfirmInfo] = useState('');
  const [loading, setLoading] = useState(false);

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true); // Start loading
    // Simulate booking confirmation process (here you would perform your actual logic)
    setTimeout(() => {
      setLoading(false); // Stop loading
      setConfirmInfo('Booking confirmed!'); // Dummy confirmation message
      // Show confirmation dialog
      alert('Booking confirmed!');
      // Redirect to home page after confirmation
      // You can replace '/home' with your actual home page route
      window.location.href = '/home';
    }, 2000); // Simulating a delay of 2 seconds for confirmation
  };

  return (
    <>
    <Header/>
    <div className="container">
      <h1>Booking for Activity: {activityId}</h1>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="hostSuggestions">Host Suggestions:</label>
          <textarea
            id="hostSuggestions"
            value={hostSuggestions}
            onChange={(e) => setHostSuggestions(e.target.value)}
          />
        </div>
        {loading ? (
          <CircularProgress /> // Show loader while processing
        ) : (
          <button type="submit">Book</button>
        )}
      </form>
      {/* Payment confirmation dialog */}
      {/* Displayed via Alert */}
      <a href="/home" className="link">Go back to Home</a>
    </div>
    </>
  );
}

export default BookingExp;
