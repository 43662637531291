import React from "react";
import logo from "../../logo-shippi.png";
import { Link } from "react-router-dom";
import Footer from "../../components/footer/Footer";
import Header from "../../components/Header";
import {
  Typography,
  Chip,
  Avatar,
  Grid,
  Card,
  CardContent,
  Container,
  Box,
} from "@mui/material";
import "./Home.css";

function Home() {
  const features = [
    {
      title: "Personalized Listings",
      description:
        "Host and join activities tailored to your interests and preferences. From professional seminars to social dinners and adventurous trips, you'll find opportunities that match your passions.",
      color: "#ff6d75",
    },
    {
      title: "Brain Development Hub",
      description:
        "Access a curated collection of resources and challenges designed to stimulate cognitive skills and promote problem-solving abilities. Our content is tailored to various fields and interests, ensuring continuous growth and development.",
      color: "#ff6d75",
    },
    {
      title: "Interactive User Profiles",
      description:
        "Showcase your interests, skills, and experiences to connect with like-minded individuals. Our profiles highlight your unique strengths and achievements, making it easier to find and connect with people who share your goals.",
      color: "#ff6d75",
    },
    {
      title: "Seamless Communication",
      description:
        "Stay connected with built-in messaging and notification systems. Our platform ensures you can easily coordinate with others, stay informed about upcoming events, and maintain meaningful interactions.",
      color: "#ff6d75",
    },
  ];

  const FeatureCard = ({ title, description, color }) => (
    <Grid item xs={12} sm={6}>
      <Card
        style={{
          borderRadius: "15px",
          boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
        }}
      >
        <CardContent>
          <Typography
            variant="h6"
            style={{
              color,
            }}
          >
            {title}
          </Typography>
          <Typography
            variant="body2"
            style={{ marginTop: "10px", lineHeight: "1.6" }}
          >
            {description}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );

  return (
    <div className="App">
      <Header />
      <header className="App-header">
        <div className="content-wrapper">
          <img src={logo} className="App-logo" alt="Shippi Logo" />
          <Typography variant="h4" gutterBottom style={{ color: "#ff6d75" }}>
            Welcome to Shippi! 🌟
          </Typography>
          <Typography variant="body1" className="intro-text" gutterBottom>
            Shippi is revolutionizing traditional networking by providing
            personalized opportunities to connect, learn, travel, and enhance
            cognitive skills. Our platform fosters meaningful connections,
            tailored mentorship, and diverse experiences to empower personal and
            professional growth.
          </Typography>
          <div className="chip-container">
            <Chip
              avatar={<Avatar>#1</Avatar>}
              label="Personalized Listings"
              variant="outlined"
              color="primary"
              className="chip"
            />
            <Chip
              avatar={<Avatar>#2</Avatar>}
              label="Brain Development Hub"
              variant="outlined"
              color="primary"
              className="chip"
            />
            <Chip
              avatar={<Avatar>#3</Avatar>}
              label="Interactive User Profiles"
              variant="outlined"
              color="primary"
              className="chip"
            />
            <Chip
              avatar={<Avatar>#4</Avatar>}
              label="Seamless Communication"
              variant="outlined"
              color="primary"
              className="chip"
            />
          </div>
          <nav>
            <Link to="/blog" className="Blog-link">
              Go to Blog Section
            </Link>
          </nav>
        </div>

        {/* New Content Starts Here */}
        <Container>
          <Typography
            variant="h5"
            gutterBottom
            style={{
              color: "#000000",
              marginTop: "40px",
              fontWeight: "400",
              letterSpacing: "1.5px",
              marginBottom: "10px",
            }}
          >
            Our Mission
          </Typography>
          <Typography variant="body1" gutterBottom style={{ color: "#000" }}>
            At Shippi, our mission is to empower individuals to achieve their
            full potential by creating meaningful connections and providing
            opportunities for continuous learning and personal growth. We
            believe that by offering a platform that integrates networking with
            cognitive skill enhancement, we can help individuals navigate their
            personal and professional journeys more effectively.
          </Typography>

          <Typography
            variant="h5"
            gutterBottom
            style={{
              color: "#000000",
              marginTop: "40px",
              fontWeight: "400",
              letterSpacing: "1.5px",
              marginBottom: "10px",
            }}
          >
            Key Features
          </Typography>

          <Grid container spacing={3}>
            {features.map((feature, index) => (
              <FeatureCard
                key={index}
                title={feature.title}
                description={feature.description}
                color={feature.color}
              />
            ))}
          </Grid>

          <Typography
            variant="h5"
            gutterBottom
            style={{
              color: "#000000",
              marginTop: "40px",
              fontWeight: "400",
              letterSpacing: "1.5px",
              marginBottom: "10px",
            }}
          >
            Join Us, Launching Soon..
          </Typography>
          <Typography variant="body1" gutterBottom style={{ color: "#000" }}>
            At Shippi, we're always looking for passionate individuals to join
            our team. If you're interested in helping us achieve our mission and
            making a positive impact, we'd love to hear from you.
          </Typography>
        </Container>
      </header>
      <Footer />
    </div>
  );
}

export default Home;
