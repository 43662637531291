import React, { useState } from "react";
import { Link } from "react-router-dom";
import BlogData from "../../blogData.json";
import BlogCard from "../../components/BlogCard";
import "./BlogList.css"; // Import CSS for styling
import Header from "../../components/Header";
import Footer from "../../components/footer/Footer";

function BlogList() {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");

  // Filter blog data based on search term and selected category
  const filteredBlogData = BlogData.filter((post) => {
    const postCategoryLower = post.category.toLowerCase();
    const selectedCategoryLower = selectedCategory.toLowerCase();
  
    return (
      (postCategoryLower.includes(searchTerm.toLowerCase()) ||
        post.subcategory.toLowerCase().includes(searchTerm.toLowerCase())) &&
      (selectedCategory === "" || postCategoryLower === selectedCategoryLower)
    );
  });

  filteredBlogData.sort((a, b) => new Date(b.date) - new Date(a.date));

  // Extract unique categories from filtered blog data
  const categories = [...new Set(BlogData.map((post) => post.category))];

  const blogData = BlogData.filter((post) =>
    post.category.toLowerCase().includes(searchTerm.toLowerCase()) ||
    post.subcategory.toLowerCase().includes(searchTerm.toLowerCase())
  );
  blogData.sort((a, b) => new Date(b.date) - new Date(a.date));


  return (
    <>
      <Header />
      <div className="blog-list-container">
        <h2 className="blog-list-title">Shippi Blog</h2>
        <p className="blog-list-description">
          Explore insights into technology, health, finance, and more.
        </p>

        <div className="search-bar">
          <input
            type="text"
            placeholder=" Search technology, health, or fitness..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        {/* <div className="category-buttons">
          <div className="category-list">
            <button
              className={selectedCategory === "" ? "active" : ""}
              onClick={() => setSelectedCategory("")}
            >
              All
            </button>
            {categories.map((category, index) => (
              <button
                key={index}
                className={selectedCategory === category ? "active" : ""}
                onClick={() => setSelectedCategory(category)}
              >
                {category}
              </button>
            ))}
          </div>
        </div> */}

        <div className="blog-cards-container">
          {blogData.map((post) => (
            <div key={post.id} className="blog-card-wrapper">
              {post.category === "Technology" && post.subcategory === "Data Structures and Algorithms" ? (
                <Link to={`/post/${post.id}/${post.title.replace(/\s+/g, '-').toLowerCase()}`} className="blog-card-link">
                  <BlogCard post={post} />
                </Link>
              ) : (
                <Link to={`/blog/post/${post.id}/${post.title.replace(/\s+/g, '-').toLowerCase()}`} className="blog-card-link">
                  <BlogCard post={post} />
                </Link>
              )}
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default BlogList;
