// Search.js
import React from 'react';
import { TextField, Button } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import './search.css'; // Import the search.css file for styling

const Search = ({ searchQuery, setSearchQuery, handleSearch }) => {
  return (
    <div className="search-bar">
      <TextField
        variant="outlined"
        fullWidth
        placeholder="Search for listings..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        className="search-input" // Apply the search-input class for styling
        InputProps={{
          endAdornment: (
            <Button onClick={handleSearch} className="search-button"> {/* Apply the search-button class for styling */}
              <SearchIcon />
            </Button>
          ),
        }}
      />
    </div>
  );
};

export default Search;
