import React, { useState } from "react";
import Footer from "../../components/footer/Footer";
import Header from "../../components/Header";
import "./Signup.css";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";

const professions = [
  "Software Engineering",
  "Sales",
  "Marketing",
  "Data Science",
  "Business Development",
  "Project Management",
  "Human Resources",
  "Finance",
  "Healthcare",
  "Education"
];


const skills = [
  "Communication",
  "Teamwork",
  "Problem-Solving",
  "Adaptability",
  "Critical Thinking",
  "Time Management",
  "Leadership",
  "Emotional Intelligence",
  "Conflict Resolution",
  "Creativity"
];

function SignUp() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [selectedProfession, setSelectedProfession] = useState(null);
  const [selectedSkills, setSelectedSkills] = useState([]);

  const handleProfessionClick = (profession) => {
    setSelectedProfession(profession);
  };

  const handleSkillClick = (skill) => {
    setSelectedSkills((prevSkills) =>
      prevSkills.includes(skill)
        ? prevSkills.filter((s) => s !== skill)
        : [...prevSkills, skill]
    );
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("Name:", name);
    console.log("Email:", email);
    console.log("Password:", password);
    console.log("Profession:", selectedProfession);
    console.log("Skills:", selectedSkills);
  };

  return (
    <div className="App">
      <Header />
      <header className="App-header">
        <div className="content-wrapper">
          <h2 className="heading">Sign Up</h2>
          <div className="signup-box">
            <Box
              component="form"
              sx={{
                "& > :not(style)": { m: 1, width: "100%" },
              }}
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              <div className="textfield-title">Name</div>
              <TextField
                label="Enter your name"
                color="secondary"
                value={name}
                onChange={(event) => setName(event.target.value)}
                fullWidth
              />
              <div className="textfield-title">Email</div>
              <TextField
                label="Enter your email"
                color="secondary"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                fullWidth
              />
              <div className="textfield-title">Set Password</div>
              <TextField
                label="Enter your password"
                type="password"
                color="secondary"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                fullWidth
              />
              <div className="chip-container">
              <div className="textfield-title">Select Career</div>

                {professions.map((profession) => (
                  <Chip
                    key={profession}
                    label={profession}
                    clickable
                    className={
                      selectedProfession === profession
                        ? "MuiChip-clickablePrimary"
                        : ""
                    }
                    onClick={() => handleProfessionClick(profession)}
                  />
                ))}
              </div>
              <div className="chip-container">
              <div className="textfield-title">Select skills</div>
                {skills.map((skill) => (
                  <Chip
                    key={skill}
                    label={skill}
                    clickable
                    className={
                      selectedSkills.includes(skill)
                        ? "MuiChip-clickablePrimary"
                        : ""
                    }
                    onClick={() => handleSkillClick(skill)}
                  />
                ))}
              </div>

              <Link to="/preferences" style={{ textDecoration: "none" }}>
                <Button type="submit" variant="contained" color="primary">
                  Next
                </Button>
              </Link>
            </Box>
          </div>
        </div>
      </header>
      <Footer />
    </div>
  );
}

export default SignUp;
