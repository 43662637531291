import React from 'react';
import { Link } from "react-router-dom";
import './Footer.css';

function Footer() {
  return (
    <div className="Footer">
      <nav className="Footer-nav">
       
      <Link to="/blog" className="Footer-link">
          Blog
        </Link>
        <Link to="/privacy-policy" className="Footer-link">
          Privacy Policy
        </Link>
        <Link to="/about-us" className="Footer-link">
          About Us
        </Link>
        <Link to="/contact" className="Footer-link">
          Contact Us
        </Link>
      </nav>
      <div>
 <footer class="text-gray-600 body-font">
  <div class="container px-5 py-8 mx-auto flex items-center sm:flex-row flex-col">
    
    <p class="text-sm text-gray-500 sm:ml-4 sm:pl-4 sm:border-l-2 sm:border-gray-200 sm:py-2 sm:mt-0 mt-4">© 2024 ShippiLife
      {/* <a href="https://linkedin.com/kny" class="text-gray-600 ml-1" rel="noopener noreferrer" target="_blank">@shippilife</a> */}
    </p>
  
  </div>
</footer>
      </div>
    </div>
  );
}

export default Footer;
