import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../shippi-crop.png';
import './Header.css';

function Header() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="header">
      <div className="logo-container">
        <a href="https://www.shippi.life">
          <img src={logo} alt="Logo" className="logo" />
        </a>
      </div>
      <nav className={`nav-links ${isOpen ? 'open' : ''}`}>
        <Link to="/" className="nav-link">Home</Link>
        <Link to="/blog" className="nav-link">Blog</Link>
        <Link to="/about-us" className="nav-link">About </Link>
         
      </nav>
      <div className={`hamburger ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </div>
    </header>
  );
}

export default Header;
