import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import BlogData from "../../blogData.json";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import { Typography, Box, Container, Grid } from "@mui/material";
import Header from "../../components/Header";
import Footer from "../../components/footer/Footer";
import "./BlogPostGeneral.css"; // Import CSS file for styling

function BlogPostGeneral() {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [post, setPost] = useState(null);

  useEffect(() => {
    // Simulate loading time
    const timer = setTimeout(() => {
      // Find the blog post with the matching ID
      const foundPost = BlogData.find((post) => post.id === parseInt(id));
      setPost(foundPost);
      setLoading(false);
    }, 500); // Wait for 0.5 seconds

    // Clear the timer on component unmount
    return () => clearTimeout(timer);
  }, [id]);

  // If still loading, display loader
  if (loading) {
    return (
      <div className="loader-container">
        <Stack sx={{ color: "grey.500" }} spacing={2} direction="row">
          <CircularProgress style={{ color: "#ff6d75" }} />
        </Stack>
      </div>
    );
  }

  // If the post is not found, display a message
  if (!post) {
    return <div>Blog post not found.</div>;
  }

  // If the post is found, display its content
  return (
    <>
      <Header />
      <Container maxWidth="md" className="blog-post-container">
        <Box
          sx={{
            height: 140,
            background: "linear-gradient(to right, rgb(236 255 239), rgb(255, 230, 230))",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "#000",
            borderRadius: 1,
            mb: 2,
          }}
        >
          <Typography variant="h5" sx={{ fontSize: "20px" }}>
            {post.category}
          </Typography>
        </Box>
        <Box className="blog-post-header" sx={{ textAlign: "center", mb: 4 }}>
          <Typography variant="h4" className="post-title">
            {post.title}
          </Typography>
          <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
            <span style={{ marginRight: "8px", display: "inline-flex", alignItems: "center" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-person-fill"
                viewBox="0 0 16 16"
              >
                <path d="M8 0a4 4 0 0 1 4 4c0 2.5-2 4-4 4S4 6.5 4 4a4 4 0 0 1 4-4zM1.5 14c0-1.5 3.5-2.5 6.5-2.5s6.5 1 6.5 2.5v1H1.5v-1z" />
              </svg>
            </span>
            <span>{post.author}</span>
            <span style={{ marginLeft: "16px", marginRight: "8px", display: "inline-flex", alignItems: "center" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-calendar"
                viewBox="0 0 16 16"
              >
                <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm2-1a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v1H1V1zm0 14h14V6H1v9zM5 8h2v2H5V8zm3 0h2v2H8V8zm-3 3h2v2H5v-2zm3 0h2v2H8v-2zm3 0h2v2h-2v-2z" />
              </svg>
            </span>
            <span>{post.date}</span>
          </Typography>
        </Box>
        <Box className="blog-post-content">
          {post.content.sections.map((section, index) => (
            <Box className="blog-section" key={index} sx={{ mb: 4 }}>
              <Typography variant="h5" className="section-title">
                {section.title}
              </Typography>
              <Typography variant="body1" className="section-content" sx={{ mt: 2 }}>
                {section.content}
              </Typography>
              {section.images && (
                <Grid container spacing={2} sx={{ mt: 2 }}>
                  {section.images.map((image, idx) => (
                    <Grid item xs={12} sm={6} key={idx}>
                      <img src={image.url} alt={image.alt} className="section-image" />
                    </Grid>
                  ))}
                </Grid>
              )}
            </Box>
          ))}
          <Box className="blog-section">
            <Typography variant="h5" className="section-title">
              Conclusion
            </Typography>
            <Typography variant="body1" className="section-content" sx={{ mt: 2 }}>
              {post.content.conclusion}
            </Typography>
          </Box>
          <Box className="blog-post-meta" sx={{ textAlign: "center", mt: 4 }}>
            <Typography variant="body1" className="meta-info">
              <strong>Category:</strong> {post.category}
            </Typography>
          </Box>
        </Box>
        <Footer />
      </Container>
    </>
  );
}

export default BlogPostGeneral;
