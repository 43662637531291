import React, { useState } from 'react';
import './ActivityPurpose.css';

const ActivityPurpose = ({ onValidationChange, values }) => {
  const [purpose, setPurpose] = useState(values.purpose || '');

  const handlePurposeChange = (newPurpose) => {
    setPurpose(newPurpose);
    onValidationChange('purpose', newPurpose); // Notify parent component of the value change
  };

  return (
    <div className="activity-purpose">
      <h3>Which of the following best describes your purpose?</h3>
      <div className="purpose-options">
        <div
          className={`purpose-option ${purpose === 'healing' ? 'selected' : ''}`}
          onClick={() => handlePurposeChange('healing')}
        >
          Healing
        </div>
        <div
          className={`purpose-option ${purpose === 'leadership' ? 'selected' : ''}`}
          onClick={() => handlePurposeChange('leadership')}
        >
          Leadership
        </div>
        <div
          className={`purpose-option ${purpose === 'cooking' ? 'selected' : ''}`}
          onClick={() => handlePurposeChange('cooking')}
        >
          Cooking
        </div>
        <div
          className={`purpose-option ${purpose === 'goodVibes' ? 'selected' : ''}`}
          onClick={() => handlePurposeChange('goodVibes')}
        >
          Good Vibes
        </div>
      </div>
    </div>
  );
};

export default ActivityPurpose;
