import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import BlogData from "../../blogData.json";
import "./BlogPost.css"; // Import CSS file for styling
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import { Typography } from "@mui/material";
import Header from "../../components/Header";
import Footer from "../../components/footer/Footer";

function BlogPost() {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [post, setPost] = useState(null);

  useEffect(() => {
    // Simulate loading time
    const timer = setTimeout(() => {
      // Find the blog post with the matching ID
      const foundPost = BlogData.find((post) => post.id === parseInt(id));
      setPost(foundPost);
      setLoading(false);
    }, 500); // Wait for 0.5 seconds

    // Clear the timer on component unmount
    return () => clearTimeout(timer);
  }, [id]);

  // If still loading, display loader
  if (loading) {
    return (
      <div className="loader-container">
        <Stack sx={{ color: "grey.500" }} spacing={2} direction="row">
          <CircularProgress style={{ color: "#ff6d75" }} />
        </Stack>
      </div>
    );
  }

  // If the post is not found, display a message
  if (!post) {
    return <div>Blog post not found.</div>;
  }

  // If the post is found, display its content
  return (
    <>
     <Header />
     <div style={{marginTop:10}}>
    <div className="blog-post-container">
      <div
        style={{
          height: 140,
          background:
            "linear-gradient(to right, rgb(236 255 239), rgb(255, 230, 230))", // Updated background gradient
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "#000", // Black text color
          borderRadius: 5,
        }}
      >
        <Typography variant="h5" style={{ fontSize: "20px" }}>
          {post.title}
        </Typography>
      </div>
      <div className="blog-post-header">
        {/* <img src={post.headerImage} alt={post.title} /> */}
        {/* <h2>{post.title}</h2> */}
        <p
          style={{
            fontSize: "14px",
            color: "#777",
            marginTop: "8px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span style={{ marginRight: "8px" }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-person-fill"
              viewBox="0 0 16 16"
            >
              <path d="M8 0a4 4 0 0 1 4 4c0 2.5-2 4-4 4S4 6.5 4 4a4 4 0 0 1 4-4zM1.5 14c0-1.5 3.5-2.5 6.5-2.5s6.5 1 6.5 2.5v1H1.5v-1z" />
            </svg>
          </span>
          <span>{post.author}</span>
          <span style={{ marginLeft: "16px", marginRight: "8px" }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-calendar"
              viewBox="0 0 16 16"
            >
              <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm2-1a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v1H1V1zm0 14h14V6H1v9zM5 8h2v2H5V8zm3 0h2v2H8V8zm-3 3h2v2H5v-2zm3 0h2v2H8v-2zm3 0h2v2h-2v-2z" />
            </svg>
          </span>
          <span>{post.date}</span>
        </p>
      </div>
      <div className="blog-post-content">
        <div className="problem-statement">
          <h2>Problem Statement</h2>
          <p>{post.content.description}</p>
        </div>
        <div className="examples" style={{ marginBottom: "1rem" }}>
          <h3 style={{ fontSize: "1.2rem" }}>Examples</h3>
          <ul style={{ listStyle: "none", paddingLeft: 0 }}>
            {post.content.examples.map((example, index) => (
              <li key={index} style={{ marginBottom: "0.5rem" }}>
                <p style={{ margin: 0, fontSize: "1rem" }}>
                  Input: {JSON.stringify(example.input)}
                </p>
                <p style={{ margin: 0, fontSize: "1rem" }}>
                  Output: {example.output}
                </p>
              </li>
            ))}
          </ul>
        </div>

        <div className="edge-cases">
          <h3>Edge Cases</h3>
          <ul>
            {post.content.edgeCases.map((edgeCase, index) => (
              <li key={index}>{edgeCase}</li>
            ))}
          </ul>
        </div>
        <h2>Approaches</h2>
        {post.content.bruteForce && (<div
          className="brute-force"
          style={{ background: "linear-gradient(to right, #ffffff, #f2f2f2)" }}
        >
          <h3 className="section-heading">Brute Force</h3>
          <ul>
            {post.content.bruteForce.explanation.map((point, index) => (
              <li key={index}>{point}</li>
            ))}
          </ul>
          <h4 className="edge-cases-heading">Edge Cases:</h4>
          <ul>
            {post.content.bruteForce.edgeCases.map((edgeCase, index) => (
              <li key={index}>{edgeCase}</li>
            ))}
          </ul>
          <p>
            <strong>Time Complexity:</strong>{" "}
            {post.content.bruteForce.timeComplexity}
          </p>
          <p>
            <strong>Space Complexity:</strong>{" "}
            {post.content.bruteForce.spaceComplexity}
          </p>
        </div>)}
        {post.content.betterApproach && (
          <div
            className="better-approach"
            style={{
              background: "linear-gradient(to right, #ffffff, #e6ffe6)",
            }}
          >
            <h3 className="section-heading">Better Approach</h3>
            {/* <p>
              <strong>Explanation:</strong>{" "}
              {post.content.betterApproach.explanation}
            </p> */}
            <ul>
              {post.content.betterApproach.explanation.map((point, index) => (
                <li key={index}>{point}</li>
              ))}
            </ul>
            <h4 className="edge-cases-heading">Edge Cases:</h4>
            <ul>
              {post.content.betterApproach.edgeCases.map((edgeCase, index) => (
                <li key={index}>{edgeCase}</li>
              ))}
            </ul>
            <p>
              <strong>Time Complexity:</strong>{" "}
              {post.content.betterApproach.timeComplexity}
            </p>
            <p>
              <strong>Space Complexity:</strong>{" "}
              {post.content.betterApproach.spaceComplexity}
            </p>
          </div>
        )}
        <div
          className="optimal-approach"
          style={{ background: "linear-gradient(to right, #ffffff, #e6e6ff)" }}
        >
          <h3 className="section-heading">Optimal Approach</h3>
          <ul>
            {post.content.optimalApproach.explanation.map((point, index) => (
              <li key={index}>{point}</li>
            ))}
          </ul>
          <h4 className="edge-cases-heading">Edge Cases:</h4>
          <ul>
            {post.content.optimalApproach.edgeCases.map((edgeCase, index) => (
              <li key={index}>{edgeCase}</li>
            ))}
          </ul>
          <p>
            <strong>Time Complexity:</strong>{" "}
            {post.content.optimalApproach.timeComplexity}
          </p>
          <p>
            <strong>Space Complexity:</strong>{" "}
            {post.content.optimalApproach.spaceComplexity}
          </p>
        </div>
        <div className="github-link">
          <a
            href={post.githubSolutionLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            {/* <img src="/github-logo.png" alt="GitHub Logo" /> */}
            <span>Dive Deep into GitHub Code 🌊</span>
          </a>
        </div>
        <div className="blog-post-meta">
          <p>
            <strong>Category:</strong> {post.category}
          </p>
          <p>
            <strong>Companies mentioned:</strong> {post.companies.join(", ")}
          </p>
        </div>
      </div>
      <Footer />
    </div>
    </div>
    </>
  );
}

export default BlogPost;
